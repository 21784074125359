/* import React, { useState } from 'react';
import { Outlet, Link, NavLink } from "react-router-dom";
import sofia from "../../assets/sofia_ico.png";
import unina from "../../assets/logo-unina.png";
import styles from "./Layout.module.css";

import Dialog from './Dialog';  // Import the Dialog component
import Credits from './Credits';  // Import the Dialog component

const Layout = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showCredits, setShowCredits] = useState<boolean>(false);

    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);

    const openCredits = () => setShowCredits(true);
    const closeCredits = () => setShowCredits(false);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Sofia 2.0 | DISES - Federico II</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <button onClick={openDialog} className={styles.rectangular_button} style={{ marginRight: '10px' }}>
                                    Chi è Sofia?
                                </button>
                            </li>
                            <li>
                                <button onClick={openCredits} className={styles.rectangular_button} style={{ marginLeft: '10px' }}>
                                    Credits
                                </button>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a href="https://www.dises.unina.it/" target={"_blank"} title="Link DISES">
                                    <img
                                        src={sofia}
                                        alt="Sofia logo"
                                        aria-label="Link to DiSES Hompage"
                                        className={styles.sofiaLogo}
                                    />
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <img src={unina} alt="Unina logo" className={styles.uninaLogo} />
                </div>
            </header>
            <Dialog show={showDialog} onClose={closeDialog} />
            <Credits show={showCredits} onClose={closeCredits} />
            <Outlet />
        </div>
    );
};

export default Layout; */

import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
//import sofia from "../../assets/sofia_ico.png";
import unina from "../../assets/logo-unina.png";
import styles from "./Layout.module.css";
import IG_pic from "../../assets/ig_logo.png";
import FB_pic from "../../assets/fb_logo.png";

import Dialog from './Dialog'; // Import the Dialog component
import Credits from './Credits'; // Import the Dialog component

const Layout = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showCredits, setShowCredits] = useState<boolean>(false);

    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);

    const openCredits = () => setShowCredits(true);
    const closeCredits = () => setShowCredits(false);

    // Hook per rilevare se l'utente è su mobile
    const isMobile = window.matchMedia("(max-width: 480px)").matches;

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    {/* {!isMobile && (
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h3 className={styles.headerTitle}>Sofia 2.0 | DISES - Federico II</h3>
                        </Link>
                    )} */}
                    {!isMobile && (
                        <div className={styles.headerTitleContainer}>
                            <h3 className={styles.headerTitle}>Sofia | DISES - Federico II</h3>
                        </div>
                    )}
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <button onClick={openDialog} className={styles.rectangular_button} style={{ marginRight: '10px' }}>
                                    Chi è Sofia?
                                </button>
                            </li>
                            <li>
                                <button onClick={openCredits} className={styles.rectangular_button} style={{ marginLeft: '10px' }}>
                                    Credits
                                </button>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a href="https://www.instagram.com/disesunina" target={"_blank"} title="Link Instagram">
                                    <img
                                        src={IG_pic}
                                        alt="IG logo"
                                        aria-label="Link to DiSES Instagram account"
                                        className={styles.IGLogo}
                                    />
                                </a>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a href="https://www.facebook.com/AskDises" target={"_blank"} title="Link Facebook">
                                    <img
                                        src={FB_pic}
                                        alt="FB logo"
                                        aria-label="Link to DiSES Facebook account"
                                        className={styles.IGLogo}
                                    />
                                </a>
                            </li>
                            <li className={styles.uninaLogo}>
                                <a href="https://www.dises.unina.it/" target={"_blank"} title="Link DiSES">
                                    <img
                                        src={unina}
                                        alt="Unina logo"
                                        aria-label="Link to DiSES website"
                                        className={styles.uninaLogo}
                                    />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <Dialog show={showDialog} onClose={closeDialog} />
            <Credits show={showCredits} onClose={closeCredits} />
            <Outlet />
        </div>
    );
};

export default Layout;
