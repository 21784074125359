import { useState, useEffect } from "react";
import sofiaAnswer from "../../assets/sofiaAnswer.png";
import sofiaDisclaimer from "../../assets/sofiaDisclaimer.png";

export const AnswerIcon = () => {
    return <img src={sofiaAnswer} alt="Logo risposta" aria-hidden="true" aria-label="Answer logo" width="50" height="50" />;
};

/* export const DisclaimerIcon = () => {
    return <img src={sofiaDisclaimer} alt="Logo disclaimer" aria-hidden="true" aria-label="Disclaimer logo" width="150" height="50" style={{ marginRight: "100px" }} />
}; */

export const DisclaimerIcon = () => {
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 480px)").matches);

    useEffect(() => {
        const handler = () => setIsMobile(window.matchMedia("(max-width: 480px)").matches);
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);

    return <img src={sofiaDisclaimer} alt="Logo disclaimer" aria-hidden="true" aria-label="Disclaimer logo" width="150" height="50" style={isMobile ? {} : { marginRight: "100px" }} />;
};
