//FILE DI PROVA DOVE HO MESSO SOFIA E SOFIA 3D INSIEME

console.log("Caricamento Sofia");

import * as THREE from "./build/three.module.js";

import { OrbitControls } from "./jsm/controls/OrbitControls.js";
import { GLTFLoader } from "./jsm/loaders/GLTFLoader.js";
//import { RoughnessMipmapper } from './jsm/utils/RoughnessMipmapper.js';

const DEFAULT_ZOOM = 6; //6 originariamente

var idleAnimation, speackAnimation;
var okAnimation = [];
var noAnimation = [];

var camera;
let scene, renderer, object;
var loadingManager;

var nextAnimation = null;

var w, h;

const clock = new THREE.Clock();

let mixer;

var waitingTextArray;
var waitingTextIndex = 0;
var threadId;

const IDLE = 1;
const SPEACK = 0;

const OK_1 = 2;
const OK_2 = 3;
const OK_3 = 5;

const NO_1 = 4;
const NO_2 = 6;
const NO_3 = 7;
const NO_4 = 8;
const NO_5 = 9;

let artificialDelay = 500; // Delay in milliseconds per ogni aggiornamento
let progress = 0;

let container;

/* FUNZIONI INIT */
function closePopup() {
    const overlay = document.getElementById("feedback-popup-overlay");
    const popup = document.getElementById("feedback-popup");

    if (overlay && popup) {
        overlay.style.display = "none";
        popup.style.display = "none";
    }
}

function feedbackButton() {
    // Mostra il popup dopo 5 minuti
    setTimeout(() => {
        const overlay = document.getElementById("feedback-popup-overlay");
        const popup = document.getElementById("feedback-popup");

        if (overlay && popup) {
            overlay.style.display = "block";
            popup.style.display = "block";
        }
    }, 3); //00000 300.000 ms = 5 minuti   //900000); // 900.000 ms = 15 minuti

    // Gestione pulsanti
    const yesButton = document.getElementById("yes-button");
    const notNowButton = document.getElementById("not-now-button");

    if (yesButton && notNowButton) {
        yesButton.addEventListener("click", () => {
            window.open("https://forms.gle/B4Xq8MQy3kiAudAZA", "_blank"); //questa soluzione apre una nuova scheda di navigazione
            closePopup();
            //window.location.href = 'https://forms.gle/B4Xq8MQy3kiAudAZA'; //questa soluzione apre la form sulla stessa pagina
        });

        notNowButton.addEventListener("click", closePopup);
    }
}

// Funzione per verificare e impostare i cookie con SameSite=None e Secure
function checkAndSetCookies() {
    // Ottiene tutti i cookie in un'unica stringa e li separa in un array
    const cookies = document.cookie.split(";");

    // Cicla attraverso ogni cookie trovato
    cookies.forEach(cookie => {
        // Divide ogni cookie in nome e valore
        const [name, value] = cookie.split("=");

        // Mostra il nome e il valore del cookie nella console (utile per debugging)
        console.log(`Cookie: ${name.trim()}, Valore: ${value}`);

        // Verifica che il cookie abbia sia un nome sia un valore prima di procedere
        if (name && value) {
            // Reimposta il cookie con gli attributi SameSite=None e Secure per garantire compatibilità cross-site
            document.cookie = `${name.trim()}=${value}; SameSite=None; Secure; path=/`;
        }
    });
}

function handleOrientationChange() {
    const leftColumn = document.querySelector(".left-column");
    let currentOrientation = window.innerHeight > window.innerWidth ? "portrait" : "landscape";

    if (currentOrientation === "portrait") {
        // Nascondi la colonna di sinistra in portrait
        leftColumn.classList.add("hidden");
        leftColumn.classList.remove("visible");
    } else {
        // Mostra la colonna di sinistra in landscape
        leftColumn.classList.add("visible");
        leftColumn.classList.remove("hidden");
    }
}

function onTransitionEnd(event) {
    event.target.remove();
    clearTimeout(threadId);

    for (var i = 0; i < waitingTextArray.length; i++) {
        var nextWaitingText = waitingTextArray[waitingTextIndex];
        $(nextWaitingText).hide();
    }
}

function onWindowResize() {
    camera.aspect = w / h;
    camera.updateProjectionMatrix();
    renderer.setSize(w, h);
}
/* FINE FUNZIONI INIT */
/*--------------------------------------------------------------------------------------------------------------------*/
/* FUNZIONI LOADOBJECT */

function faceCamera(obj) {
    obj.rotation.y = Math.PI / 8;
    renderer.render(scene, camera);
}

function showWelcomeButton() {
    const button = document.createElement("button");
    button.id = "welcome-button";
    button.innerText = "Ciao, clicca qui per ascoltare il mio\nmessaggio di benvenuto!";

    // Trova la colonna di sinistra e aggiungi il pulsante
    const leftColumn = document.querySelector(".left-column");
    leftColumn.insertBefore(button, leftColumn.firstChildChild);
    button.addEventListener("click", function () {
        playText("Ciao, sono Sofia, l'assistente virtuale del DiSES. Come posso aiutarti?");
        button.remove(); // Rimuove il pulsante dopo il clic
    });
}

function simulateLoading(xhr) {
    const total = xhr.total || 1; // Assicurati che non sia zero
    const loaded = xhr.loaded || 0;

    // Calcola la percentuale di avanzamento in base al caricamento effettivo
    let percentage = (loaded / total) * 100;

    // Se non c'è una risposta, aggiungi un ritardo artificiale
    if (progress < percentage) {
        progress = Math.min(progress + 5, percentage); // Aumenta del 5% per ogni aggiornamento
    }

    console.log(progress + "% loaded");
    $(".waiting-text").text(Math.floor(progress) + "%");

    // Aggiungi il delay artificiale
    setTimeout(function () {
        if (xhr.loaded >= xhr.total) {
            const loadingScreen = document.getElementById("loading-screen");
            loadingScreen.classList.add("fade-out");
            setTimeout(function () {
                $(loadingScreen).hide();
                // Assicuriamoci di non creare più di un bottone
                if (!document.getElementById("welcome-button")) {
                    showWelcomeButton();
                }
            }, 500);
        }
    }, artificialDelay);
}

// Funzione per gestire il caricamento con xhr
function onLoadProgress(xhr) {
    simulateLoading(xhr);
}

/* FINE FUNZIONI LOADOBJECT */
/*--------------------------------------------------------------------------------------------------------------------*/
/* FUNZIONI DI TEXT-TO-SPEECH ED ANIMAZIONI */
window.audioStarts = function () {
    console.log("audioStarts");

    // Assicurati che idleAnimation e speackAnimation siano definiti e accessibili
    if (typeof idleAnimation !== "undefined" && typeof speackAnimation !== "undefined") {
        idleAnimation.stop();
        speackAnimation.play();
        console.log("speackAnimation");
    } else {
        console.error("idleAnimation or speackAnimation is not defined");
    }
};

window.audioEnds = function () {
    console.log("audioEnds");

    // Assicurati che speackAnimation, nextAnimation, e idleAnimation siano definiti e accessibili
    if (typeof speackAnimation !== "undefined" && typeof nextAnimation !== "undefined" && typeof idleAnimation !== "undefined") {
        speackAnimation.stop();

        nextAnimation.loop = THREE.LoopOnce;
        nextAnimation.play();

        setTimeout(function () {
            nextAnimation.stop();
            idleAnimation.play();
        }, nextAnimation.getClip().duration * 1000);

        $("#chat-submit").removeAttr("disabled");
    } else {
        console.error("speackAnimation, nextAnimation, or idleAnimation is not defined");
    }
};

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function playText(testo) {
    // Funzione per rimuovere la sezione "Citazioni" e i riferimenti ai PDF
    const correggiTesto = testo => {
        // Rimuovi tutto il testo a partire dalla parola "Citazioni" fino alla fine
        const removeCitazioniText = testo.replace(/Citazioni:([\s\S]*)/g, "").trim();

        // Rimuovi i riferimenti ai PDF (quelli tra parentesi quadre)
        const removePdfReferences = removeCitazioniText.replace(/\[.*?\]/g, "").trim();

        // Rimuovi ".00" e sostituisci ".30" con "e trenta"
        const adjustNumbers = removePdfReferences
            .replace(/\.00/g, "")
            .replace(/\:00/g, "")
            .replace(/\.30/g, "e trenta")
            .replace(/\:30/g, "e trenta")
            .replace(/\.30/g, "e trenta")
            .replace(/\.09/g, "nove");

        return adjustNumbers;
    };

    // Rimuovi la sezione "Citazioni" e tutto ciò che segue
    const testoCorretto = correggiTesto(testo);

    console.log("Testo corretto:", testoCorretto); // Aggiungi un log per vedere se il testo è stato effettivamente modificato

    // Controlla il contenuto per selezionare l'animazione appropriata
    if (testoCorretto.includes("Mi dispiace") || testoCorretto.includes("Purtroppo") || testoCorretto.includes("Non")) {
        var n = getRandomInt(noAnimation.length);
        nextAnimation = noAnimation[n];
    } else {
        var n = getRandomInt(okAnimation.length);
        nextAnimation = okAnimation[n];
    }

    // Parametri per la voce
    var parameters = {
        pitch: 1.15,
        rate: 1.15,
        onstart: audioStarts,
        onend: audioEnds
    };

    // Riproduci il testo pulito (senza citazioni)
    responsiveVoice.speak(testoCorretto, "Italian Female", parameters);
}

/* FINE FUNZIONI DI TEXT-TO-SPEECH ED ANIMAZIONI */
/*--------------------------------------------------------------------------------------------------------------------*/

console.log("Fine Sofia");

/*--------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------------------------*/
//INIZIO SOFIA 3D
console.log("Caricamento Sofia3d");

function init() {
    console.log("Inizio INIT");

    //AVVIAMENTO TIMER PER IL POPUP DELLA FINESTRA DI FEEDBACK
    //feedbackButton();

    //INSERIMENTO VERIFICA COOKIE PER PERMETTERE AL BROWSER DI FAR PARTIRE IL SINTETIZZATORE
    checkAndSetCookies();

    /*Questa funzione garantisce che i cookie vengano inviati correttamente con le richieste verso il servizio di 
	sintesi vocale di terze parti, assicurando che il servizio funzioni come previsto, soprattutto se si tratta di 
	richieste cross-origin.
	*/

    waitingTextArray = $(".waiting-text");

    container = document.getElementById("center-text"); //tolto center-text e messo root
    console.log(container);
    if (container) {
        console.log("Container defined");
    } else {
        console.log("Container is not defined");
    }

    // Controlla l'orientamento iniziale
    handleOrientationChange();

    // Aggiungi l'evento resize
    window.addEventListener("resize", handleOrientationChange);

    if (window.innerHeight > window.innerWidth) {
        w = 152; //190
        h = 305.36; //381.7
    } else {
        w = $(container).width();
        h = $(container).height();
    }
    console.log("The value of w is: " + w);
    console.log("The value of h is: " + h);

    camera = new THREE.PerspectiveCamera(DEFAULT_ZOOM, w / h, 1, 1000);
    camera.position.set(100, 20, 300);

    scene = new THREE.Scene();

    loadingManager = new THREE.LoadingManager(() => {
        const loadingScreen = document.getElementById("loading-screen");
        loadingScreen.classList.add("fade-out");

        // optional: remove loader from DOM via event listener
        loadingScreen.addEventListener("transitionend", onTransitionEnd);
    });

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hemiLight.position.set(0, 200, 0);
    scene.add(hemiLight);

    const dirLight = new THREE.DirectionalLight(0xffffff);
    dirLight.position.set(0, 0, 100);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 180;
    dirLight.shadow.camera.bottom = -100;
    dirLight.shadow.camera.left = -120;
    dirLight.shadow.camera.right = 120;
    scene.add(dirLight);

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(w, h);
    renderer.shadowMap.enabled = true;

    console.log("sto per stampare renderer");
    console.log(renderer.domElement);

    container.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = 2;
    controls.maxDistance = 20;
    controls.target.set(0, -0.4, 0);

    controls.update();

    // you can easily disable it by using
    controls.enabled = false;

    window.addEventListener("resize", onWindowResize);

    console.log("Fine INIT");
}

function animate() {
    requestAnimationFrame(animate);
    const delta = clock.getDelta();
    if (mixer) mixer.update(delta);
    renderer.render(scene, camera);
}

function loadObject() {
    console.log("Inizio LOAD");

    const path = "https://sofia-the-conversational-bot-unina.vercel.app/sofia.glb";
    const gltfLoader = new GLTFLoader();
    console.log("load model");

    gltfLoader.load(
        path,
        gltf => {
            console.log("model loaded");

            object = gltf.scene;

            mixer = new THREE.AnimationMixer(object);

            idleAnimation = mixer.clipAction(gltf.animations[IDLE]);
            console.log("sto per fare il playAnimationIdle");
            idleAnimation.play();
            console.log("ho fatto il playAnimationIdle");

            speackAnimation = mixer.clipAction(gltf.animations[SPEACK]);

            okAnimation.push(mixer.clipAction(gltf.animations[OK_1]));
            okAnimation.push(mixer.clipAction(gltf.animations[OK_2]));
            okAnimation.push(mixer.clipAction(gltf.animations[OK_3]));

            noAnimation.push(mixer.clipAction(gltf.animations[NO_1]));
            noAnimation.push(mixer.clipAction(gltf.animations[NO_2]));
            noAnimation.push(mixer.clipAction(gltf.animations[NO_3]));
            noAnimation.push(mixer.clipAction(gltf.animations[NO_4]));
            noAnimation.push(mixer.clipAction(gltf.animations[NO_5]));

            object.traverse(function (child) {
                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });
            scene.add(object);
            faceCamera(object);
        },
        function (xhr) {
            // Chiamata per monitorare il progresso
            onLoadProgress(xhr);
        },
        function (error) {
            console.log("An error happened");
            console.log(error);
        }
    );

    console.log("Fine LOAD");
}

init();
animate();
loadObject();
